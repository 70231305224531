import React from 'react';
import { useHistory } from 'react-router-dom';
import '../App.css';
import ReservedNamesIcon from '../Images/ReservedNames.svg';
import PromoCodesIcon from '../Images/PromoCodes.svg';

const PremiumSubs = () => {
    const history = useHistory();

    const navigateToReservedNames = () => {
        history.push('/premium/reserved_names');
    };

    const navigateToPromoCodes = () => {
        history.push('/premium/promo_codes');
    };

    return (
        <>
            <div className="MainBody">
                <div className="PremiumHeader">
                    <div className="UMTitle">subscriptions</div>
                    <div className="PremiumPageButtons">
                        <button className="ReservedNamesButton" onClick={navigateToReservedNames}>
                            <img src={ReservedNamesIcon} alt="Reserved Names"></img>
                        </button>
                        <button className="PromoCodesButton" onClick={navigateToPromoCodes}>
                            <img src={PromoCodesIcon} alt="Promo Codes"></img>
                        </button>
                    </div>
                </div>
                <div className="PremiumCardZone">
                    <div className="PremiumCard">
                        <div className="PremiumCardTitle1">Active Subs</div>
                    </div>
                    <div className="PremiumCard">
                        <ul className="PremiumCardTitle2">Sub Revenue</ul>
                    </div>
                    <div className="PremiumCard">
                        <ul className="PremiumCardTitle3">Sub Annual Value</ul>
                    </div>
                    <div className="PremiumCard">
                        <ul className="PremiumCardTitle4">New Subs 24h</ul>
                    </div>
                    <div className="PremiumCard">
                        <ul className="PremiumCardTitle5">Sub Rev. Projection</ul>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PremiumSubs;
