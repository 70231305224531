import React from 'react'
import '../App.css'


const PromoCodes = () => {
    return (
    <><div className="MainBody">
         <div className="UMTitle">promo codes</div>
    </div>
    </>
    )
}
 export default PromoCodes